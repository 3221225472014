<template>
  <div class="dropdown-item" :class="{ disabled }">
    <slot v-bind="{ disabled, selected }" />
    <BIconCheck v-if="selected" />
  </div>
</template>

<script>
import { BIconCheck } from 'bootstrap-vue'
import px from 'vue-types'

export default {
  name: 'DropdownItem',
  components: { BIconCheck },
  props: {
    disabled: px.bool.def(false),
    selected: px.bool.def(false),
  },
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  // reset bootstrap styles
  padding: 0.25em 0.5em;
  border-radius: 0;

  &:hover {
    background: $warning;
  }
}
</style>
