import axios from '../axios.js'

const DEFAULT_AUTO_COMPLETE_LIMIT = 50
/**
 * @param {string} search
 * @param cancelToken
 * @return {Promise<API_COLLEGIATE_TEAM_AUTOCOMPLETE[]>}
 */
export const getCollegiateTeamsAutocomplete = async (search, { cancelToken } = {}) => {
  const response = await axios.get('/val/v3/collegiate/teams', {
    cancelToken,
    params: { name: search, limit: DEFAULT_AUTO_COMPLETE_LIMIT },
  })
  return response.data.collegiate_teams || []
}

/**
 * @param {string} search
 * @param cancelToken
 * @return {Promise<API_ESPORT_TEAM_AUTOCOMPLETE[]>}
 */
export const getEsportTeamsAutocomplete = async (search, { cancelToken } = {}) => {
  const response = await axios.get('/val/v3/esport/teams', {
    cancelToken,
    params: { name: search, limit: DEFAULT_AUTO_COMPLETE_LIMIT },
  })
  return response.data.esport_teams || []
}

/**
 * @param {string} search
 * @param cancelToken
 * @return {Promise<API_EVENT_AUTOCOMPLETE[]>}
 */
export const getEventsAutocomplete = async (search, { cancelToken } = {}) => {
  const response = await axios.get('/val/v3/esport/events', {
    cancelToken,
    params: { name: search, limit: DEFAULT_AUTO_COMPLETE_LIMIT },
  })
  return response.data.esport_events || []
}

/**
 * @param {string} search
 * @param cancelToken
 * @return {Promise<API_ESPORT_PLAYER_AUTOCOMPLETE[]>}
 */
export const getEsportPlayersAutocomplete = async (search, { cancelToken } = {}) => {
  const response = await axios.get('/val/v3/esport/players', {
    cancelToken,
    params: { name: search },
  })
  return response.data.esport_players || []
}

/**
 * @param {string} search
 * @param cancelToken
 * @return {Promise<API_ESPORT_PLAYER_AUTOCOMPLETE[]>}
 */
export const getCollegiatePlayersAutocomplete = async (search, { cancelToken } = {}) => {
  const response = await axios.get('/val/v3/collegiate/players', {
    cancelToken,
    params: { name: search },
  })
  return response.data.collegiate_players || []
}
