<template>
  <VueAutosuggest ref="autosuggest" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </VueAutosuggest>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'AppAutosuggest',
  components: { VueAutosuggest },
}
</script>

<style lang="scss" scoped>
/*   ::v-deep {
    .autosuggest__results-container {
      position: relative;
      width: 100%;
    }

    .autosuggest__results {
      margin: 0;
      position: absolute;
      z-index: 10000001;
      width: 100%;
      border: 1px solid #ccc;
      padding: 0;
      max-height: 400px;
      overflow-y: auto;
      background: $body-bg;
    }

    .autosuggest__results ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }

    .autosuggest__results .autosuggest__results-item {
      cursor: pointer;
    }

    ul:nth-child(1) > .autosuggest__results_title {
      border-top: none;
    }

    .autosuggest__results .autosuggest__results-before {
      color: gray;
      font-size: 11px;
      margin-left: 0;
      padding: 15px 13px 5px;
      border-top: 1px solid lightgray;
    }

    .autosuggest__results .autosuggest__results-item:active,
    .autosuggest__results .autosuggest__results-item:hover,
    .autosuggest__results .autosuggest__results-item:focus,
    .autosuggest__results
    .autosuggest__results-item.autosuggest__results-item--highlighted {
      background-color: $warning;
    }
  } */
</style>
